import Link from "next/link";
import { OptionsType, PageType } from "../../utils/baseTypes";
import { getPathNameArray, optimizeURL } from "../../utils/url";
import { sanitize } from "isomorphic-dompurify";

interface BreadcrumbProps {
  post: PageType;
  options?: OptionsType;
  breadcrumbsClasses?: string;
}

const titleize = (slug: string): string => {
  var words = slug.split("-");
  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
};

export const Breadcrumbs = ({
  post,
  breadcrumbsClasses,
  options,
}: BreadcrumbProps) => {
  const paths = getPathNameArray(post.link);
  const postType = post.type;

  return (
    <div
      className={`breadcrumbs is-hidden-mobile ${
        breadcrumbsClasses ? breadcrumbsClasses : "pb-6"
      }`}
    >
      <div>
        <Link href="/" prefetch={false}>
          <span>Startseite</span>
        </Link>
        <span>{" » "}</span>
        {post.type &&
          postType !== "projects" &&
          paths.map((path, i) => {
            if (i === paths.length - 1) return false;
            let path_link = "";
            for (let j = 0; j < paths.length; j++) {
              if (j <= i) path_link += `/${paths[j]}`;
            }
            return (
              <MiddleLink
                key={i}
                path_link={path_link}
                path={path}
                nolink={i === 1}
              />
            );
          })}
        {postType == "projects" && options && (
          <>
            {options.acf.projects_overview_page && (
              <MiddleLink
                key={"portfolio"}
                path_link={
                  optimizeURL(options.acf.projects_overview_page.url) ||
                  "/portfolio"
                }
                path={options.acf.projects_overview_page.title}
              />
            )}
          </>
        )}
        {postType == "search" && (
          <MiddleLink key={"encyclopedia"} path_link={"/wiki"} path={"wiki"} />
        )}
        {post.title?.rendered && (
          <span
            className="breadcrumbs-active"
            dangerouslySetInnerHTML={{
              __html: sanitize(post.title.rendered),
            }}
          />
        )}
      </div>
    </div>
  );
};

const MiddleLink = ({
  path_link,
  path,
  nolink = false,
}: {
  path_link: string;
  path: string;
  nolink?: boolean;
}) => {
  return (
    <>
      {nolink ? (
        <span className="is-capitalized">{titleize(path)}</span>
      ) : (
        <Link prefetch={false} href={path_link}>
          <span className="is-capitalized">{titleize(path)}</span>
        </Link>
      )}
      <span>{" » "}</span>
    </>
  );
};
