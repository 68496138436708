import React from "react";
import { useAppContext } from "../../context/AppContext";

export const DividerBg = () => {
  const { sectionAnimation, sectionBG } = useAppContext();

  if (sectionAnimation)
    return <div className={`section-divider-bg ${sectionBG}`} />;

  return <></>;
};
