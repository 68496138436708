import { AnimatePresence, motion } from "framer-motion";
import { sanitize } from "isomorphic-dompurify";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import useWindowSize from "../../hooks/useWindowSize";
import { DividerType } from "../../utils/baseTypes";
import { unlinkWiki } from "../../utils/unlinkWiki";
interface DividerProps {
  data: DividerType;
  headline?: string;
  background?: string;
}
const transition = { duration: 1, ease: [0.6, 0.01, 0.05, 0.9] };
const textRight = {
  visible: {
    opacity: 1,
    x: "-50%",
    y: "-50%",
    left: "50%",
  },
  hidden: {
    opacity: 0,
    left: "40%",
  },
};
const textLeft = {
  visible: {
    opacity: 1,
    x: "-50%",
    y: "-50%",
    left: "50%",
  },
  hidden: {
    opacity: 0,
    left: "-40%",
    top: "50%",
  },
};

const textRightSection = {
  visible: {
    opacity: 1,
    x: 0,
  },
  hidden: {
    opacity: 0,
    x: "-70%",
  },
};
const textLeftSection = {
  visible: {
    opacity: 1,
    x: 0,
  },
  hidden: {
    opacity: 0,
    x: "70%",
  },
};

//#region TO animate divider
// const transition_ = {
//   ease: [0.455, 0.03, 0.515, 0.955],
//   duration: 0.85,
// };

// const bg_text_variants = {
//   hidden: {
//     scale: 0.8,
//     opacity: 0,
//   },
//   visible: {
//     scale: 1,
//     opacity: 1,
//     transition: transition_,
//   },
// };

// const line_variant = {
//   hidden: {
//     x: "-110%",
//   },
//   visible: {
//     x: 0,
//     transition: {
//       ease: [0.455, 0.03, 0.515, 0.955],
//       duration: 1.3,
//     },
//   },
// };

//#endregion

const Divider = ({ data, headline, background = "" }: DividerProps) => {
  const { headline_im_divider, bg_text } = data;

  const {
    sectionAnimation,
    setSectionBGTxt,
    sectionBGTxt,
    sectionClamp,
    setSectionClamp,
    setSectionBG,
    setDividerTop,
    dividerTop,
  } = useAppContext();
  const dividerRef = useRef<HTMLDivElement>(null);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const [showBG, setShow] = useState<boolean>(false);

  const { width } = useWindowSize();

  useEffect(() => {
    document.fonts.ready.then(function () {
      if (dividerRef.current) {
        const elemRec = dividerRef.current.getBoundingClientRect();
        const top = elemRec.top;
        const bottom = elemRec.bottom;

        if (top < 500) {
          setSectionBGTxt(bg_text);
          setSectionClamp(bottom);
          setSectionBG(background);
          setDividerTop(top);
        }
      }
    });
    // const onPageLoad = () => {
    //   if (dividerRef.current) {
    //     const elemRec = dividerRef.current.getBoundingClientRect();
    //     const top = elemRec.top;
    //     const bottom = elemRec.bottom;

    //     if (top < 500) {
    //       setSectionBGTxt(bg_text);
    //       setSectionClamp(bottom);
    //       setSectionBG(background);
    //       setDividerTop(top);
    //     }
    //   }
    // };

    // window.addEventListener("load", onPageLoad);
    // return () => window.removeEventListener("load", onPageLoad);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    if (dividerRef.current) {
      const elemRec = dividerRef.current.getBoundingClientRect();
      const top = elemRec.top;
      if (top < 500) {
        setDividerTop(top);
      }
    }
  }, [width]);

  useEffect(() => {
    const scroolTrigger = () => {
      if (dividerRef.current) {
        const position_y = dividerRef.current.getBoundingClientRect().top;
        if (position_y < sectionClamp) {
          if (bg_text !== sectionBGTxt) {
            setSectionBGTxt(bg_text);
            setSectionBG(background);
          }
        }
      }
    };

    window?.addEventListener("scroll", scroolTrigger);

    return () => window?.removeEventListener("scroll", scroolTrigger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sectionBGTxt === bg_text) {
      setShow(true);
    } else {
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionBGTxt]);

  if (sectionAnimation)
    return (
      <>
        <div ref={dividerRef} className="divider-row columns is-multiline">
          <div className="divider-body column is-full">
            <p
              className="divider-content p-0"
              dangerouslySetInnerHTML={{
                __html: sanitize(unlinkWiki(data?.subline)),
              }}
            ></p>

            <div className="line divider-content has-white-bg" />
            <AnimatePresence>
              {showBG && (
                <>
                  <motion.p
                    ref={paragraphRef}
                    initial="hidden"
                    whileInView="visible"
                    exit="hidden"
                    variants={textLeftSection}
                    transition={{ ...transition }}
                    className="section-bg-text upper-part"
                    style={{
                      top: dividerTop,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(unlinkWiki(sectionBGTxt)),
                    }}
                  />

                  <motion.p
                    initial="hidden"
                    whileInView="visible"
                    exit="hidden"
                    variants={textRightSection}
                    transition={{ ...transition }}
                    className="section-bg-text lower-part"
                    style={{
                      top: dividerTop,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(unlinkWiki(sectionBGTxt)),
                    }}
                  />
                </>
              )}
            </AnimatePresence>
          </div>
        </div>
      </>
    );

  return (
    <motion.div
      className="divider animation-active"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      {headline_im_divider && (
        <div className="columns h-in-divider">
          <div className="is-offset-6-desktop p-0 column hide-overflow has-text-right">
            <motion.h2
              initial={{
                y: "100%",
              }}
              whileInView={{
                y: 0,
              }}
              transition={{
                ease: [0.455, 0.03, 0.515, 0.955],
                delay: 1,
                duration: 0.7,
              }}
              viewport={{ once: true }}
              className="m-0"
            >
              <strong
                dangerouslySetInnerHTML={{
                  __html: sanitize(headline ?? "", {
                    ADD_ATTR: ["target"],
                  }),
                }}
              ></strong>
            </motion.h2>
          </div>
        </div>
      )}

      <div className="divider-row columns is-multiline">
        <div className="divider-body column is-full">
          <p
            className="divider-content p-0"
            dangerouslySetInnerHTML={{
              __html: sanitize(unlinkWiki(data?.subline)),
            }}
          />

          <motion.p
            variants={textLeft}
            transition={{ ...transition }}
            className="divider-background-title upper-part"
            aria-disabled="true"
            dangerouslySetInnerHTML={{ __html: sanitize(unlinkWiki(bg_text)) }}
          />

          <div className="line divider-content has-white-bg" />

          <motion.p
            variants={textRight}
            transition={{ ...transition }}
            className="divider-background-title lower-part"
            aria-disabled="true"
            dangerouslySetInnerHTML={{ __html: sanitize(unlinkWiki(bg_text)) }}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Divider;
