import { Dispatch, SetStateAction, useState, useEffect } from "react";
import dynamic from "next/dynamic";

import type { DotLottiePlayer } from "@johanaarstein/dotlottie-player";

interface AnimationPlayerProp {
  url: string;
  //? this option is needed because when animation in rendered as
  //? in small size settin this true will slice the animtion
  //? set true if you are rendering in large size
  AnimationRef?: Dispatch<SetStateAction<DotLottiePlayer | null>>;
  loop?: boolean;
  autoplay?: boolean;
  objectfit?: "contain" | "cover" | "fill";
  renderer?: "svg" | "canvas";
}

//? the player will not work on ssr so it will only import on client side
const DotPlayer = dynamic(() => import("./DotPlayer"), {
  loading: () => <></>,
  ssr: false,
});

const AnimationPlayer = ({
  url,
  AnimationRef,
  autoplay = true,
  loop = true,
  objectfit = "contain",
  renderer = "svg",
}: AnimationPlayerProp) => {
  return (
    <DotPlayer
      src={url}
      AnimationRef={AnimationRef}
      autoplay={autoplay}
      loop={loop}
      objectfit={objectfit}
      renderer={renderer}
    />
  );
};

//! old lottie animation component

// interface AnimationPlayerOldProp {
//   url: string;
//   preserveAspectRatio?: boolean;
// }

// export const AnimationPlayerOld = ({
//   url,
//   preserveAspectRatio = false,
// }: AnimationPlayerOldProp) => {
//   const [animData, setAnimData] = useState(null);

//   useEffect(() => {
//     async function loadAnimtion() {
//       const anim_data = await fetch(url, {
//         mode: "cors",
//       }).then((res) => res.json());

//       setAnimData(anim_data);
//     }

//     loadAnimtion();
//   }, [url]);

//   return (
//     <>
//       {animData ? (
//         <Lottie
//           className="animation-player"
//           animationData={animData}
//           loop={true}
//           autoPlay={true}
//           renderer="svg"
//           rendererSettings={{
//             preserveAspectRatio: preserveAspectRatio ? "xMidYMid slice" : "",
//           }}
//         />
//       ) : (
//         ""
//       )}
//     </>
//   );
// };

export default AnimationPlayer;
