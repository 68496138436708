import { useEffect, useState } from "react";
// import Lottie from "lottie-react";
import { AnimatePresence, motion } from "framer-motion";

import { defaultEase } from "../utils/transition";

import AnimationPlayer from "../components/AnimationPlayer";

//! animtion JSON
// import loaderAnim from "./loader_final.json";

export const Loader = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
        transition: {
          ease: [0.455, 0.03, 0.515, 0.955],
          duration: 0.6,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          ease: [0.455, 0.03, 0.515, 0.955],
          duration: 0.5,
        },
      }}
      viewport={{ once: true }}
      className="loading-screen"
    >
      <div className="loader-wrapper">
        <AnimationPlayer url="/loader_final.lottie" />
      </div>
    </motion.div>
  );
};

export const FullScreenLoader = () => {
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    if (showLoader) {
      document.querySelector<HTMLElement>("html")!.style.overflow = "hidden";
    } else {
      document.querySelector<HTMLElement>("html")!.style.overflow = "";
    }
  }, [showLoader]);

  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => setShowLoader(false), 1500);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <>
      <AnimatePresence>
        {showLoader && (
          <motion.div
            exit={{
              y: "100%",
              opacity: 0,
              transition: {
                ease: defaultEase,
                duration: 0.8,
              },
            }}
            className="fullscreen-loader"
          >
            <div className="loader-wrapper">
              <AnimationPlayer url="/loader_final.lottie" />
            </div>
            {/* <Lottie
              animationData={loaderAnim}
              loop={true}
              autoPlay={true}
              renderer="svg"
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
              style={{
                minWidth: 300,
              }}
            /> */}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export const StickyLoader = () => {
  return (
    <motion.div
      initial={{ display: "none" }}
      animate={{ display: "block", transition: { delay: 1 } }}
      exit={{ display: "none" }}
      className="loader-sticky-wrapper"
    >
      <Loader />
    </motion.div>
  );
};
