import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { useAppContext } from "../../../context/AppContext";
import Arrow from "../../../images/arrow_dark.svg";
import Close from "../../../images/close_x.svg";
import ScrollLink from "../ScrollLink";
import { defaultEase } from "../../../utils/transition";

const transition_ = {
  ease: defaultEase,
  duration: 0.5,
};

const nav_variant = {
  hidden: {
    x: "100%",
    transition: transition_,
  },
  visible: {
    x: 0,
    transition: transition_,
  },
};

const nav_link_variant = {
  hidden: {
    x: "calc(100% - 60px)",
    transition: transition_,
  },
  visible: {
    x: 0,
    transition: transition_,
  },
};

export const InlineNav = () => {
  const [animate, setanimate] = useState<boolean>(false);

  const { inlineNavInView, inlineLinks, showInlineNav } = useAppContext();

  useEffect(() => {
    if (inlineNavInView) {
      setanimate(false);
    }
  }, [inlineNavInView]);

  if (!showInlineNav) return <></>;

  return (
    <motion.div
      initial={"hidden"}
      variants={nav_variant}
      animate={inlineNavInView ? "hidden" : "visible"}
      className="inline-nav"
    >
      <motion.div
        variants={nav_link_variant}
        initial={"hidden"}
        animate={animate ? "visible" : "hidden"}
        className="is-flex"
      >
        <motion.div className="action-wrapper">
          <button
            onClick={() => setanimate((last) => !last)}
            aria-label="page-navigation"
          >
            <AnimatePresence>
              {animate ? (
                <motion.i
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    ease: "circIn",
                    duration: 1,
                  }}
                >
                  <Close width={22} height={22} />
                </motion.i>
              ) : (
                <motion.i
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    ease: "circIn",
                    duration: 1,
                  }}
                >
                  <Arrow width={22.06} height={14.58} />
                </motion.i>
              )}
            </AnimatePresence>
          </button>
        </motion.div>
        <motion.div className="link-wrapper">
          {inlineLinks.map((link: any, i: number) => (
            <ScrollLink key={i} link={link} />
          ))}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
