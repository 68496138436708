import { sanitize } from "isomorphic-dompurify";
import { PageType } from "../../utils/baseTypes";
import { motion } from "framer-motion";
import { CustomLink } from "../Global/CustomLink";
interface WikiPostProps {
  data: PageType;
  idx: number;
  postPerPage?: number;
  postToLoad?: number;
}
export const WikiPost = ({
  data,
  idx,
  postPerPage,
  postToLoad,
}: WikiPostProps) => {
  let realIndex = idx;
  if (typeof postPerPage === "number" && typeof postToLoad === "number") {
    realIndex = idx < postPerPage - 1 ? idx % postPerPage : idx % postToLoad;
  }

  const title = data.title.rendered;
  const content = data.acf?.wiki_header?.content;
  // remove HTML tags
  const contentString =
    content?.replace(/<[^>]+>/g, "").substring(0, 70) + "...";

  const transition_ = {
    ease: [0.455, 0.03, 0.515, 0.955],
    duration: 0.6,
  };
  const variants_grid = {
    hidden: {
      y: 50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <div className="column is-6-tablet is-4-desktop is-3-fullhd">
      <CustomLink href={`/wiki/${data.slug}`} target="_self" disablePrefetch>
        <motion.div
          className="wiki-box"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants_grid}
          transition={{ ...transition_, delay: realIndex / 10 }}
        >
          <div className="border-corner upper right" />
          <div className="border-corner upper left" />
          <div className="wiki-info bgLightGrey">
            <div className="box-title">
              <span
                className="h3"
                dangerouslySetInnerHTML={{
                  __html: sanitize(title, {
                    ADD_ATTR: ["target"],
                  }),
                }}
              ></span>
            </div>
            {contentString && (
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: sanitize(contentString, {
                    ADD_ATTR: ["target"],
                  }),
                }}
              />
            )}
          </div>
          <div className="border-corner lower right" />
          <div className="border-corner lower left" />
        </motion.div>
      </CustomLink>
    </div>
  );
};
