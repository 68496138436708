import { KeyboardEventHandler, MouseEventHandler } from "react";
import SearchIcon from "../images/search-icon.svg";
export const SearchBar = ({
  headline,
  searchHandler,
  clickhandler,
}: {
  headline: string;
  searchHandler: KeyboardEventHandler<HTMLInputElement>;
  clickhandler?: MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div className={`searchbar column is-12 input-group`}>
      {headline && <span className="h3">{headline}</span>}
      <div className="searchbar-container">
        <input
          className="searchbar-input"
          name="searchbar"
          placeholder="Suche"
          autoComplete="off"
          onKeyUp={searchHandler}
        />
        <div onClick={clickhandler} className={`searchbar-icon`}>
          <SearchIcon />
        </div>
      </div>
    </div>
  );
};
